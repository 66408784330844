@import "src/scss/module";

.JobsCarousel {
  position: relative;
  .bg_1 {
    top: 7rem;
    left: 0;
  }

  .bg_2 {
    bottom: 4rem;
    left: 20%;
  }

  .bg_3 {
    top: 7rem;
    right: 0;
  }

  .bg_4 {
    top: -7rem;
    right: 0;
  }

  :global {
    .swiper-wrapper {
      padding: 2rem 0 2rem 1rem;

      @include media-breakpoint-up(lg) {
        padding: 2rem 0;
      }
    }
  }

  &[data-buttons="true"] {
    :global {
      .navigation {
        justify-content: flex-start;
      }
    }
  }
}
